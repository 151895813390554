// @ts-expect-error still combination
import CallApi from "./apiCallingSetup.tsx";
import {GetTimeExPointsResponse, OfferObject, UpdateBidResponse} from "./ApiUrlsSetupTypes";
// @ts-ignore
import {OrganisationListResult} from "./type.ts";

export const API_BASE_URL = "https://api.community.wellbeingchampions.sg";

export const updateBid = async (data: {
  status: "cancelled" | "confirmed",
  oneToOneSessionId: number
}) => {
  const url = `/api/one-to-one-session`;
  return await CallApi<{ data: UpdateBidResponse }>("PUT", {url, data})
}

export const getTimeExPoints = async () => {
  const url = `/api/point/balance`;
  return await CallApi<{ data: GetTimeExPointsResponse }>("GET", {url})
}

export const createOffer = async (data: {
  offerType: "gift" | "request"
  title: string,
  description: string,
  category: string[],
  noOfHours: number,
  availability: string[],
  isClosed: boolean,
  publishedAt: Date,
  unpublishedAt?: Date,
}) => {
  const url = `/api/offer`;
  return await CallApi<{ data: OfferObject }>("POST", {url, data})
}

export const getOrgsForUser = async (status: string) => {
  const url = `/api/user/organisations?status=${status}`;
  return await CallApi<{
    data: {
      organisationArray: OrganisationListResult[]
    },
    message: string,
    statusCode: number
  }>("GET", {url});
}