import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from "react-router-dom";

const ProgramsComp = (props) => {
    const navigate = useNavigate();
    return (
        <InfiniteScroll
            className={"row g-3"}
            dataLength={props.experiences.length} //This is important field to render the next data
            next={props.getExperiences}
            hasMore={false}
            loader={
                <h4 className="text-center my-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                </h4>
            }
        >
            {props.experiences.map((val, i) => {
                return (
                    <div className={`${props.classes} h-100 tw-cursor-pointer`}>
                        <div
                            className="new_community_card"
                            key={i}
                            onClick={() => navigate(val.type === 'community' ? `/organisation/${val.id}` : val.type === 'programme' ? `/programme/${val.id}` : `/event/${val.id}`)}
                        >
                            <img src={val.image}
                                 className={"object-fit-contain tw-object-center tw-h-unset tw-max-h-[185px] lg:tw-h-[185px]"}
                                 alt=""/>

                            <div className="p-3 d-flex flex-column gap-2">
                                <p className={"p-0 m-0"}>
                                    {val.type === 'community' || val.type !== 'programme' ? val.name : val.title}
                                </p>

                                {val.type === "community" && (
                                    <span className={"p-0"}>
                                        {`${val.members.length} ${val.members.length > 1 ? 'members' : 'member'}`} | {`${val.avgPostCount} + posts a day`}
                                    </span>
                                )}
                                {val.type === "programme" && (
                                    <span className={"p-0"}>
                                        By {val.createdBy ? val.createdBy : "test user"}
                                    </span>
                                )}
                                {val.type !== "community" && val.type !== "programme" && (
                                    <span className={"p-0"}>
                                        {`${val.participants.length} ${val.participants.length > 1 ? 'participants' : 'participant'}`} | {`${val.reactionCount} + likes`}
                                    </span>
                                )}

                                <div className={"mt-1"}>
                                    <div className="category_badge experience_badge m-0 d-block">
                                        {val.type}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </InfiniteScroll>
    );
};

export default ProgramsComp
