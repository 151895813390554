import '../../container/newsFeed/newsFeeds/newsFeeds.css';
// @ts-ignore
import FeedsSubPage from "../../component/FeedsSubPage.tsx";
// @ts-ignore
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import {Link} from "react-router-dom";
// @ts-ignore
import {useZStore} from "../../utils/zStore.ts";
import {useEffect, useState} from "react";
// @ts-ignore
import {getOrgsForUser} from '../../utils/apiUrlsSetupV2.ts';
import {OrganisationListResult} from "../../utils/type";
import Calendar from "react-calendar";

const CommunityCard = ({community}: { community: OrganisationListResult }) => {
  return (
    <Link to={`/organisation/${community.id}`}
          className="card md:tw-border-0 rounded md:tw-w-full tw-w-[150px] tw-flex-shrink-0">
      <div className={"tw-relative rounded tw-aspect-video tw-border-0 tw-flex tw-items-end"}
           style={{
             backgroundImage: `url(${community.image})`,
             backgroundSize: "cover",
             backgroundRepeat: "no-repeat",
             backgroundPosition: "center",
           }}>
      </div>
      <div className={"tw-px-2 tw-py-1 tw-relative"}>
        <div className="tw-font-medium lh-1 tw-text-sm md:tw-text-md mb-1">{community.name}</div>
        <div className={"tw-text-xs tw-hidden md:tw-block lh-1"}>
          {"By "}
          <Link to={`/friends/${community.createdByUserId}`} className="text-primary">
            {community.createdByUser.firstName} {community.createdByUser.lastName}
          </Link>
        </div>
      </div>
    </Link>
  )
}

const RightPane = () => {
  const [communities, setCommunities] = useState<OrganisationListResult[]>();

  useEffect(() => {
    if (communities) return;
    getOrgsForUser("accepted").then(responseAll => {
      if (responseAll.status === 200) {
        const userOrg = responseAll.data.data.organisationArray.organisationArray.filter(
          e => e.status === "approved"
        );

        if (userOrg) {
          setCommunities([...userOrg]);
        }
      }
    })
  }, [])

  return (
    <div className={"lg:tw-sticky tw-top-24 mb-2"}>
      <h2 className={"tw-font-semibold mb-2 md:tw-block tw-hidden"}>My Communities</h2>
      <div className="tw-flex tw-overflow-auto lg:tw-flex-col tw-gap-2 pb-2 md:tw-hidden">
        {(communities ?? []).map((community, index) => <CommunityCard community={community} key={index}/>)}
      </div>

      <div className="lg:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-2 pb-2 md:tw-grid tw-hidden">
        {(communities ?? []).map((community, index) => <CommunityCard community={community} key={index}/>)}
      </div>

      <Link to={"/communities"} className={"text-end tw-block text-primary"}>
        See All Communities
      </Link>
    </div>
  )
}

const Dashboard = () => {
  const userDetails = useZStore(state => state.userDetails);

  if (!userDetails) {
    return (
      <LoggedInLayout title={"Dashboard"}>
        Loading...
      </LoggedInLayout>
    )
  }

  return (
    <>
      <LoggedInLayout title={"Dashboard"}>
        <div className="row gy-4 gx-0 gx-md-4">
          <div className={"col-xl-3 col-lg-4"}>
            <div className='lg:tw-sticky tw-top-24'>
              <div
                className='m-0 frnd_prof_dash_cover rounded-top position-relative'
                style={{backgroundImage: `url(${userDetails.backgroundImage})`}}>
              </div>

              <div className="px-4 pb-4 position-relative d-flex flex-column gap-3 bg-white rounded"
                   style={{zIndex: 10}}>
                <div className='frinds_profile_pic shadow tw-mx-auto md:tw-mx-[unset]'>
                  <img src={userDetails.profilePic || "/static/png/Male.png"} alt=''/>
                </div>

                <div className="tw-absolute top-0 end-0 tw-hidden md:tw-block">
                  <div className="m-2">
                    <Link
                      to={"/editprofile"}
                      className="btn btn-sm rounded-pill px-4 fw-semibold tw-text-xs btn-outline-info">
                      Edit Profile Info
                    </Link>
                  </div>
                </div>

                <div>
                  <p
                    className='mb-md-2 text-center text-md-start frinds_profile_name'>{userDetails.firstName} {userDetails.lastName}</p>

                  {userDetails.bio && (
                    <div className={`lh-1 tw-hidden md:tw-block`}>
                      <div dangerouslySetInnerHTML={{__html: userDetails.bio}}/>
                    </div>
                  )}
                </div>
                <div className="tw-mt-3 lg:tw-hidden tw-hidden md:tw-block">
                  <RightPane/>
                </div>
              </div>
              <div className="bg-white px-2 md:tw-hidden">
                <RightPane/>
              </div>
              <div className="tw-mt-6 xl:tw-hidden tw-hidden lg:tw-block">
                <RightPane/>
              </div>

              <div className={"tw-mt-6"}>
                <Calendar
                  className={"tw-p-3 rounded !tw-w-full"}
                  next2Label={false}
                  prev2Label={false}
                  prevLabel={false}
                  nextLabel={false}
                  minDate={new Date()}
                />
              </div>

            </div>
          </div>
          <div className={"col-xl-6 col-lg-8"}>
            <FeedsSubPage unwrap/>
          </div>
          <div className={"col-xl-3 xl:tw-block tw-hidden"}>
            <RightPane/>
          </div>
        </div>
      </LoggedInLayout>
    </>
  )
}

export default Dashboard;


