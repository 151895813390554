import Search from "../../assets/search1.png";
import {useEffect, useState} from "react";
import {getAllExperiences,} from "../../utils/apiUrlsSetup";
import CreateOrganizationModal from "../organization/components/create-organization-modal";
// @ts-ignore still combined ts with js
import ProgramsComp from "./components/programs";
import "./program.css";

// @ts-ignore still combined ts with js
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";

const Communities = () => {
  const [experienceSearch, setExperienceSearch] = useState("");

  const [openCommunityModal, setOpenCommunityModal] = useState(false);

  const [communities, setcommunities] = useState([]);

  const fetchCommunities = async () => {
    const publicCommunities = await getAllExperiences(200);

    const communities_collection = publicCommunities.data.data.experiences
      .filter(e => e.type === 'community')
      .filter(e => e.privacy === "public")
      .sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      })

    setcommunities(communities_collection);

  };

  useEffect(() => {
    fetchCommunities();
  }, []);

  return (
    <LoggedInLayout title={"Communities"}>
      <div className="d-flex flex-column gap-3">
        <div>
          <div className="input-group neworg_searchbar">
            <span className="input-group-text px-4">
              {" "}
              <img src={Search} alt=""/>{" "}
            </span>
            <input
              type="text"
              className="form-control p-0"
              value={experienceSearch}
              onChange={(e) => setExperienceSearch(e.target.value)}
              placeholder="Search for a Experience"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />

            <button
              className="create_new_group create_new_org_btn d-none d-lg-block"
              onClick={() => {
                setOpenCommunityModal(true)
              }}
            >
              Create New Community
            </button>
          </div>
        </div>

        <button
          className="create_new_group d-block d-lg-none"
          onClick={() => {
            setOpenCommunityModal(true)
          }}
        >
          Create New Community
        </button>

        <div>
          <ProgramsComp
            classes="col-xl-4 col-md-6 col-12"
            experiences={
              communities
                .filter(e => {
                  if (!experienceSearch) return true;

                  return e.name.toLowerCase().replace(/ /g, '').includes(experienceSearch.toLowerCase().replace(/ /g, ''));
                })
            }
            getExperiences={fetchCommunities}
          />
        </div>
      </div>

      <CreateOrganizationModal
        setOpenModal={(value) => {
          setOpenCommunityModal(value);
        }}
        openModal={openCommunityModal}
      />
    </LoggedInLayout>
  );
};

export default Communities;
