import {create} from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'

type UserDetails = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  mobile: string | null;
  profilePic: string;
  bio: string | null;
  currentCountry: string | null;
  role: string;
  firstTime: boolean;
  lastLogin: string;
  isEmailVerified: boolean;
  status: string;
  favouritesByUserId: any[]; // Assuming the array contains items of unknown structure
  avgRatingOfSessions: number;
  canCreateOrganisation: boolean;
  backgroundImage: string;
  reportedBy: any[]; // Assuming the array contains items of unknown structure
  reportedCount: number;
  organisation: string;
  uen: string | null;
  userType: string;
  job_title: string;
  prev_org_experience: boolean;
  certificates: any[]; // Assuming the array contains items of unknown structure
  socialUrls: Record<string, string>; // Assuming an object of key-value pairs for social URLs
  createdAt: string;
  updatedAt: string;
  userInterestArray: any[]; // Assuming the array contains items of unknown structure
};

type zStoreType = {
  userDetails: UserDetails,
  setUserDetails: (args: UserDetails) => void,
  fullscreenImgSrc: string | null,
  setFullscreenImgSrc: (args: string | null) => void,
  showingReactionTopId: string,
  setShowingReactionTopId: (args: string) => void,
}

const useZStore = create<zStoreType>()(
  devtools(
    persist(
      (set) => ({
        userDetails: null,
        setUserDetails: (userDetails) => set(() => ({userDetails: userDetails})),
        fullscreenImgSrc: null,
        setFullscreenImgSrc: (fullscreenImgSrc: string | null) => set(() => ({fullscreenImgSrc})),
        showingReactionTopId: "",
        setShowingReactionTopId: (showingReactionTopId: string) => set(() => ({showingReactionTopId})),
      }),
      {
        name: 'wbc-zstand', // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      },
    ),
  ),
)

export {useZStore};