import React from 'react';
import '../../../../container/newsFeed/newsFeeds/newsFeeds.css';
import LoggedInLayout from "../../../../component/layout/LoggedInLayout.tsx";
import FeedsSubPage from "../../../../component/FeedsSubPage.tsx";
import {useParams} from "react-router";

export default function NewsFeedsByTags() {
  const {tag} = useParams();

  return (
    <>
      <LoggedInLayout title={`#${tag}`}>
        <FeedsSubPage keyword={`#${tag}`}/>
      </LoggedInLayout>
    </>
  )
}
