import Search from "../../assets/search1.png";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {getAllExperiences, getOrgsForUser,} from "../../utils/apiUrlsSetup";
import CreateOrganizationModal from "../organization/components/create-organization-modal";
// @ts-ignore still combined ts with js
import ExperienceModal from "./ExperienceModal.tsx";
import CreateProgramModal from "./components/createProgramModal";
import ProgramsComp from "./components/programs";
import "./program.css";
import CreateEventModal from "./components/createEventModal";

// @ts-ignore still combined ts with js
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";

const Events = () => {
  const navigate = useNavigate();
  const [experienceSearch, setExperienceSearch] = useState("");

  const [organizations, setOrganizations] = useState([]);

  const [experiencesBySearch, setExperiencesBySearch] = useState([]);

  const [userOrganisation, setUserOrganisation] = useState([]);

  const [isOpenCreateExperience, setIsOpenCreateExperience] = useState(false);

  const [openProgramModal, setOpenProgramModal] = useState(false);

  const [openCommunityModal, setOpenCommunityModal] = useState(false);

  const [openEventModal, setOpenEventModal] = useState(false);

  const [experiences, setExperiences] = useState([]);

  const [experienceType, setExperienceType] = useState<"programme" | "community" | "event">();

  const [experienceCategory, setExperienceCategory] = useState('All')


  const getExperiences = async () => {
    const response = await getAllExperiences(200);
    if (response && response.status == 200) {
      setExperiences(response.data.data.experiences.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      }));
    }
  };

  const getExperiencesByKeyword = async (keyword) => {
    const response = await getAllExperiences(null, keyword);
    if (response && response.status == 200) {
      setExperiencesBySearch(response.data.data.experiences);
    }
  };

  useEffect(() => {
    getAllOrgs();
    getExperiences();
  }, []);

  useEffect(() => {
    if (experienceSearch) {
      getExperiencesByKeyword(experienceSearch);
    }
  }, [experienceSearch]);

  const naviagetToOrgById = (v) => {
    if (v.type === 'programme') {
      navigate(`/programme/${v.id}`);
    } else if (v.type === 'community') {
      navigate(`/organisation/${v.id}`);
    } else {
      navigate(`/event/${v.id}`);
    }
  };

  const getAllOrgs = async () => {
    const response = await getOrgsForUser("accepted");
    if (response.status === 200) {
      setOrganizations(
        response.data.data.organisationArray.organisationArray.filter(
          (v) => v.status !== "deleted"
        )
      );
      const userOrg =
        response.data.data.organisationArray.organisationArray.filter(
          (v) => v.status !== "deleted"
        );
      if (userOrg) {
        setUserOrganisation([...userOrg]);
      }
    }
  };

  useEffect(() => {
    if (experienceType === "programme") {
      setOpenProgramModal(true);
    } else if (experienceType === "community") {
      setOpenCommunityModal(true);
    } else if (experienceType === "event") {
      setOpenEventModal(true)
    }
  }, [experienceType]);

  const windowWidth = window.innerWidth
  let BUTTONS = [
    {
      id: "All",
      label: "All"
    },
    {
      id: "programme",
      label: "Programme"
    },
    {
      id: "community",
      label: "Community"
    },
    {
      id: "event",
      label: "Event"
    },
  ];
  return (
    <LoggedInLayout title={"Experiences"}>
      <div className="d-flex flex-column gap-3">
        <div>
          <div className="input-group neworg_searchbar">
            <span className="input-group-text px-4">
              {" "}
              <img src={Search} alt=""/>{" "}
            </span>
            <input
              type="text"
              className="form-control p-0"
              value={experienceSearch}
              onChange={(e) => setExperienceSearch(e.target.value)}
              placeholder="Search for a Experience"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />

            <button
              className="create_new_group create_new_org_btn d-none d-lg-block"
              onClick={() => {
                setIsOpenCreateExperience(true)
              }}
            >
              Create New Experience
            </button>

            {experienceSearch.length && experiencesBySearch.length ? (
              <div className="org_search_list">
                {experiencesBySearch.map((v, i) => {
                  return (
                    <div
                      key={i}
                      className="parent_div row m-0"
                      onClick={() => naviagetToOrgById(v)}
                    >
                      <div className="col-1 p-0">
                        <img
                          src={v.image ? v.image : ""}
                          width="60px"
                          height="60px"
                          alt=""
                        />
                      </div>
                      <div className="col-10">
                        <div className="col-12 p-0 org_naming mb-2">
                          {v.type === 'programme' ? v.title : v.name}
                        </div>
                        <div
                          className="col-12 p-0 d-flex align-items-center sub_names">
                          <p className="mb-0">
                            {v.type !== 'community' && v.type !== 'programme' ? 'event' : v.type}
                          </p>
                          <p className="mb-0 ps-2">
                            {v.type === 'programme' ? `by ${v.createdBy}` : v.type !== 'community' ? `starts on ${moment(v.startDate).utc().format(
                              "DD MMM YYYY"
                            )}` : null}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>

        <button
          className="create_new_group d-block d-lg-none"
          onClick={() => {
            setIsOpenCreateExperience(true)
          }}
        >
          Create New Experience
        </button>

        <div
          className='d-flex align-items-center gap-lg-3 gap-1 justify-content-lg-start justify-content-between'>
          {BUTTONS.map(button => {
            return (
              <button
                type={"button"}
                className={`lg:tw-text-sm tw-text-xs small px-lg-3 px-2 text-nowrap newsfeed_btn ${experienceCategory === button.id ? "newsfeed_btn_active" : ""}`}
                onClick={() => setExperienceCategory(button.id)}>
                {button.label}
              </button>
            )
          })}
        </div>

        <div>
          <p className="new_community_heading mb-0">Experiences</p>
        </div>

        <div>
          <ProgramsComp
            classes="col-xl-4 col-md-6 col-12"
            experiences={
              (experienceCategory === 'All' ? experiences : experienceCategory === 'event' ? experiences.filter(e => e.type !== 'community' && e.type !== 'programme') : experiences.filter(e => e.type === experienceCategory))
                .filter(e => e.privacy === "public")
            }
            getExperiences={getExperiences}
          />
        </div>
      </div>

      <ExperienceModal
        isOpen={isOpenCreateExperience}
        onCommonModalButtonClick={() => {
          setIsOpenCreateExperience(false)
        }}
        setExperienceType={(arg) => {
          setIsOpenCreateExperience(false)
          setExperienceType(arg)
        }}
      />

      {openProgramModal ? (
        <CreateProgramModal
          setOpenModal={(value) => {
            getAllOrgs();
            setOpenProgramModal(value);
            if (!value) {
              setExperienceType('')
            }
          }}
          userOrganisation={userOrganisation}
          openModal={openProgramModal}
        />
      ) : null}

      {openCommunityModal ? (
        <CreateOrganizationModal
          setOpenModal={(value) => {
            getAllOrgs();
            setOpenCommunityModal(value);
            if (!value) {
              setExperienceType('')
            }
          }}
          openModal={openCommunityModal}
        />
      ) : null}
      {
        openEventModal ? (
          <CreateEventModal
            isOpenModal={openEventModal}
            navigate={navigate}
            handleModal={() => {
              setOpenEventModal(false);
              setExperienceType('')
              // setShowEvents(false);
            }}
            windowWidth={windowWidth}
          />
        ) : null
      }
    </LoggedInLayout>
  );
};

export default Events;
