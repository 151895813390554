import NewsFeed from "../../assets/feeds_icon.png";
import HomeLogo from "../../assets/HomeLogo.png";
import Community from "../../assets/experiences_icon.png";
import TimeExchange from "../../assets/giving_icon.png";
import Experts from "../../assets/supporters_new.png";
import Friends from "../../assets/friends_icon.png";
import Chat from "../../assets/chat_icon.png";
import Notifications from "../../assets/notification_icon.png";
import UserListsvg from "../../assets/user_list.png";
import Organisations from "../../assets/community_icon.png";
import ReportImg from "../../assets/report.png";
import QuestManagement from "../../assets/svg/Quest Management.svg";

export const MENU = [
  {
    img: HomeLogo,
    href: "/dashboard",
    label: "Dashboard",
    rules: {}
  },
  {
    img: Community,
    href: "/communities",
    label: "Community",
    rules: {
      blacklist: ["super-admin"],
    },
  },
  {
    img: TimeExchange,
    href: "/time-exchange",
    label: "Giving",
    rules: {
      blacklist: ["super-admin"],
    },
  },
  {
    img: Experts,
    href: "/supporters",
    label: "Supporters",
    rules: {
      whitelist: [],
    },
  },
  {
    img: Friends,
    href: "/friends",
    label: "Friends",
    rules: {
      blacklist: ["super-admin"],
    },
  },
  {
    img: Chat,
    href: "/chat",
    label: "Chat",
    rules: {
      blacklist: ["super-admin"],
    },
    notificationCount: 0
  },
  {
    img: Notifications,
    href: "/notification",
    label: "Notifications",
    rules: {
      blacklist: ["super-admin"],
    },
    notificationCount: 0
  },
  {
    img: UserListsvg,
    href: "/userlist",
    label: "User List",
    rules: {
      whitelist: ["super-admin"],
    },
  },
  {
    img: Organisations,
    href: "/organisation",
    label: "Community",
    rules: {
      whitelist: ["super-admin"],
    },
  },
  {
    img: ReportImg,
    href: "/reports",
    label: "Reports",
    rules: {
      whitelist: ["super-admin"],
    },
  },
  {
    img: Community,
    href: "/admin/experiences",
    label: "Experiences",
    rules: {
      whitelist: ["super-admin"],
    },
  },
  {
    img: QuestManagement,
    href: "#",
    label: "Quest Management",
    rules: {
      whitelist: ["super-admin"],
    },
  },
  {
    img: Friends,
    href: "/friends",
    label: "Friends",
    rules: {
      whitelist: ["super-admin"],
    },
  },
  {
    img: Notifications,
    href: "/notification",
    label: "Notifications",
    rules: {
      whitelist: ["super-admin"],
    },
    notificationCount: 0
  },
  {
    img: Chat,
    href: "/chat",
    label: "Chat",
    rules: {
      whitelist: ["super-admin"],
    },
    notificationCount: 0
  },
]