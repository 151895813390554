import React, {useEffect, useState} from "react";

import {getEventData, updateEventbyAdmin} from "../../utils/apiUrlsSetup";

import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import FullWidthTable from "../../component/FullWidthTable";
import {Link} from "react-router-dom";
import CreateEventModalAdmin from "./CreateEventModalAdmin";
import Swal from "sweetalert2";

const Experiences = () => {
  const [events, setEvents] = useState([]);

  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1)
  const LIMIT = 10;
  const TOTAL_PAGE = Math.ceil(events.length / LIMIT)
  const hasNextPage = currentPage < TOTAL_PAGE;
  const hasPrevPage = currentPage > 1;

  function nextPage() {
    if (currentPage < TOTAL_PAGE) {
      setCurrentPage(prev => prev + 1);
    }
  }

  function prevPage() {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1)
    }
  }

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    const response = await getEventData();
    if (response && response.status === 200) {
      setEvents(response.data.data.eventArray);
    }
  };

  const deleteEvent = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async e => {
      if (e.isConfirmed) {
        const response = await updateEventbyAdmin(id);
        if (response.status === 200) {
          fetchEvents();
        }
      }
    })
  }

  return (
    <LoggedInLayout title={"Experiences"}>


      <FullWidthTable
        title={"Manage Events"}
        searchValue={search}
        searchPlaceholder={"Search for an event"}
        searchOnChange={(e) => setSearch(e.target.value)}
        topRightButton={
          <CreateEventModalAdmin/>
        }
        columns={[
          {
            label: "Event",
            showOnDesktop: true,
            showOnMobile: true
          },
          {
            label: "Type",
            showOnDesktop: true,
            centered: true,
            showOnMobile: true
          },
          {
            label: "Status",
            showOnDesktop: true,
            showOnMobile: true,
            centered: true,
          },
          {
            label: "Time",
            showOnDesktop: true,
            showOnMobile: true,
            centered: true,
          },
          {
            label: "Creator",
            showOnDesktop: true,
            showOnMobile: true
          },
          {
            label: "Action",
            showOnDesktop: true,
            showOnMobile: false,
            centered: true
          },
        ]}
        data={events}
        rows={[
          {
            render: (val) => {
              return (
                <div className="d-flex flex-column gap-2 py-3 py-lg-1">
                  <div>
                    <img src={val.image} className="rounded tw-max-w-[300px]" alt=""/>
                  </div>
                  <div className={"lh-1 mb-1"}>
                    <Link
                      to={'#'}
                      className='montserrat fw-semibold text-primary'>
                      {val.name}
                    </Link>
                  </div>
                  <div>
                    {val.category.map(e => {
                      return (
                        <div className={"tw-px-3 tw-py-0.5 rounded-pill border border-primary tw-w-fit text-primary"}>
                          {e}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            },
          },
          {
            render: (val) => {
              return (
                <div className={"tw-font-semibold"}>
                  {val.type[0].toUpperCase()}{val.type.slice(1)} Event
                </div>
              )
            }
          },
          {
            render: (val) => {
              if (new Date(val.endDate) < new Date()) {
                return (
                  <div className={"text-center"}>
                    <div className="tw-font-semibold tw-text-green-600">
                      Done
                    </div>

                    {val.participants.length} Participants
                  </div>
                )
              }
              return (
                <div className={"text-center"}>
                  <div className={"tw-font-semibold data-[status='active']:tw-text-green-600"} data-status={val.status}>
                    {val.status}
                  </div>
                  {val.participants.length} Participants
                </div>
              )
            }
          },
          {
            render: (val) => {
              return (
                <div className={"!tw-text-opacity-50 !tw-text-[#334d6e] tw-text-nowrap tw-text-center"}>
                  <div>
                    {
                      Intl.DateTimeFormat("En-SG", {
                        weekday: "long",
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date(val.startDate))
                    }
                  </div>
                  <div>
                    to
                  </div>
                  <div>
                    {
                      Intl.DateTimeFormat("En-SG", {
                        weekday: "long",
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date(val.endDate))
                    }
                  </div>
                </div>
              )
            }
          },
          {
            render: (val) => {
              return (
                <Link to={`/admin/user-profile/${val.createdByUser.id}`}>
                  <div
                    className={"text-nowrap text-primary"}>{val.createdByUser.firstName} {val.createdByUser.lastName}</div>
                  <div>{val.createdByUser.email}</div>
                </Link>
              )
            }
          },
          {
            render: (val) => {
              return (
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <button
                    className={"tw-w-[100px] btn btn-outline-danger btn-sm tw-text-xs d-flex justify-content-center align-items-center gap-1"}
                    type={"button"}
                    onClick={() => deleteEvent(val.id)} title={"Deactivate"}>
                    <i className="bi bi-trash"/> Deactivate
                  </button>
                </div>
              )
            }
          }
        ]}
        footer={
          <div className={"w-100 tw-flex tw-items-center tw-justify-between"}>
            <div className="d-flex gap-lg-2 align-items-lg-center flex-column flex-lg-row">
              <div className={"tw-font-medium"}>Page {currentPage}</div>
              <div className="d-none d-lg-block text-secondary fw-semibold">|</div>
              Showing {LIMIT * (currentPage - 1) + 1} - {Math.min(LIMIT * currentPage, events.length)} of
              total {events.length} items
            </div>

            <div className={"d-flex align-items-center justify-content-center gap-3"}>
              <button
                type={"button"}
                disabled={!hasPrevPage}
                onClick={hasPrevPage ? prevPage : () => {
                }}
                className={"btn p-0 tw-w-[30px] tw-h-[30px] d-flex justify-content-center align-items-center bg-white border"}>
                <i className="bi bi-chevron-left"></i>
              </button>
              <button
                disabled={!hasNextPage}
                onClick={hasNextPage ? nextPage : () => {
                }}
                type={"button"}
                className={"btn p-0 tw-w-[30px] tw-h-[30px] d-flex justify-content-center align-items-center bg-white border"}>
                <i className="bi bi-chevron-right"></i>
              </button>
            </div>
          </div>
        }
      />

    </LoggedInLayout>
  );
};

export default Experiences;
