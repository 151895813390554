import {MENU} from "./MENU";
import {useEffect, useState} from "react";
import {usePubNub} from "pubnub-react";
import {useDispatch, useSelector} from "react-redux";
import {add, savePoints, saveTourData} from "../../redux/actions/action";
import {getMetaData, getNotifications, getTimeExPoints} from "../../utils/apiUrlsSetup";
import {onMessageListener} from "../../firebase/Firebase";
import {Link, useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
// @ts-ignore
import _ from "lodash";
// @ts-ignore
import Logo from "../../assets/wcn_logo.svg";
// @ts-ignore
import LogOut from "../../assets/signout_new_icon.png";
import {greeting} from "../../utils/constant";
// @ts-ignore
import {useZStore} from "../../utils/zStore.ts";

export default function TopNav({title}) {
  const [open, setOpen] = useState(false)
  const [meOpen, setMeOpen] = useState(false)
  const zStore = useZStore()

  const [notificationsCount, setnotificationsCount] = useState(Number(sessionStorage.getItem("notificationsCount")) || 0);
  const pubnub = usePubNub();

  const dispatch = useDispatch();
  const data = useSelector(state => state);

  const [messagedReveived, setMessageReveived] = useState(false);

  const logOutFunc = () => {
    dispatch(add({}));
    dispatch(saveTourData({}));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("notificationsCount");
    zStore.setUserDetails(null);
    navigate('/')
  }

  useEffect(() => {
    GetAllNotificationsCountFunc();
    return () => {
    }
  }, []);


  const GetAllNotificationsCountFunc = async () => {
    let responseData = await getNotifications();
    if (responseData.status === 200) {
      sessionStorage.setItem("notificationsCount", responseData.data.data.unreadCount);
      setnotificationsCount(responseData.data.data.unreadCount);
    } else {
    }
  }

  onMessageListener()
    .then(() => {
      GetAllNotificationsCountFunc();
    })
    .catch((err) => console.error("failed: ", err));

  const navigate = useNavigate();
  const location = useLocation()

  const role = zStore.userDetails?.role

  useEffect(() => {
    if (role !== "super-admin") {
      if (_.isEmpty(data.points.points)) {
        getTimeExchangePoints();
      }
    }
    if (_.isEmpty(data.tourData.tourData)) {
      getTourData();
    }
  }, []);

  const getTimeExchangePoints = async () => {
    const responseData = await getTimeExPoints();
    if (responseData.status === 200) {
      dispatch(savePoints(responseData.data.data));
    }
  }

  const getTourData = async () => {
    const responseData = await getMetaData();
    if (responseData.status === 200) {
      dispatch(saveTourData(responseData.data.data));
    }
  }

  pubnub.addListener({
    message: function () {
      setMessageReveived(true);
    },
  });

  return (
    <div className={"tw-fixed tw-top-0 tw-start-0 tw-w-full tw-z-50"}>
      <nav className={"shadow-sm montserrat position-relative"} style={{
        background: "linear-gradient(119.23deg,#e9dfff 4.63%,#edfaff 53.65%)",
        zIndex: 1001
      }}>
        <div className="tw-container">
          <div className="row align-items-center g-0">
            <div className="col-4 col-lg-6 d-flex align-items-center justify-content-start py-2">
              <div>
                <img src={Logo} alt='logo' width={130 * .6} height={59 * .6}/>
              </div>
            </div>
            <div className="col-4 text-center fw-bold tw-block lg:tw-hidden  py-2">
              {title}
            </div>
            <div className="col-4 col-lg-6">
              <div className="d-flex gap-4 justify-content-end align-items-center">
                {MENU
                  .filter(menu => menu.rules.whitelist ? menu.rules.whitelist.includes(role) : true)
                  .filter(menu => menu.rules.blacklist ? !menu.rules.blacklist.includes(role) : true)
                  .map((menu, i) => {
                    return (
                      <Link to={menu.href}
                            data-active={location.pathname.startsWith(menu.href)}
                            className={`gap-1 align-items-center flex-column lg:tw-flex tw-hidden tw-pt-3 tw-pb-1 tw-border-transparent tw-border-b-[0.2rem] data-[active=true]:tw-border-[#410db7]`}
                            key={i}>
                        <img src={menu.img} alt='' className={"tw-flex-shrink-0"} width={"30px"} height={"30px"}/>
                        <span
                          className={`tw-text-xs tw-text-center !tw-leading-3 montserrat tw-font-[500] tw-text-[#410db7] lh-base ${location.pathname.startsWith(menu.href) ? "fw-bold" : ""} ${menu.href === "#" ? "text-secondary" : ""}`}
                        >
                            {menu.label}
                        </span>

                        {(menu.href === "/notification" && notificationsCount > 0) && (
                          <div className="flex-grow-1 d-flex justify-content-end align-items-center">
                            <div
                              className="tw-text-xs tw-w-[18px] tw-h-[18px] bg-danger rounded-circle montserrat text-white ls-near d-flex justify-content-center align-items-center">
                              {notificationsCount}
                            </div>
                          </div>
                        )}

                        {(menu.href === "/chat" && messagedReveived) && (
                          <div className="flex-grow-1 d-flex justify-content-end align-items-center">
                            <div
                              className="tw-w-[10px] tw-h-[10px] bg-danger rounded-circle">
                            </div>
                          </div>
                        )}
                      </Link>
                    )
                  })}

                <div
                  data-active={location.pathname.startsWith("/profile")}
                  className={`gap-1 position-relative align-items-center flex-column lg:tw-flex tw-hidden tw-pt-3 tw-pb-1 tw-border-transparent tw-border-b-[0.2rem] data-[active=true]:tw-border-[#410db7]`}
                  key={"profile-link"}>
                  <img
                    onClick={() => setMeOpen(prev => !prev)} src={zStore?.userDetails.profilePic} alt=''
                    className={"rounded-circle tw-cursor-pointer tw-flex-shrink-0"}
                    width={"30px"}
                    height={"30px"}/>
                  <div
                    onClick={() => setMeOpen(prev => !prev)}
                    className={`tw-text-xs tw-flex montserrat tw-font-[500] tw-cursor-pointer tw-text-[#410db7] lh-base ${location.pathname.startsWith("/profile") ? "fw-bold" : ""}`}
                  >
                      Me
                  <i
                    className="bi bi-caret-down-fill d-inline-block tw-cursor-pointer" style={{
                    transform: "translate(3px, 1px)",
                  }}/>
                  </div>
                  {meOpen && (
                    <div
                      onBlurCapture={() => setMeOpen(prev => !prev)}
                      className={"position-absolute bg-white tw-w-[300px] tw-end-0 tw-top-full tw-flex tw-flex-col tw-gap-2 shadow-sm px-3 py-2 rounded"}>

                      <Link to={"/profile"} className={"tw-flex tw-items-center tw-gap-2"}>
                        <img src={zStore?.userDetails.profilePic} alt='' className={"rounded-circle tw-cursor-pointer"}
                             width={"60px"}
                             height={"60px"}/>

                        <div className={"tw-font-medium mb-0 mt-2"}>
                          <p className='mb-0 montserrat small tw-font-[500] lh-1'>{greeting()},</p>
                          {zStore?.userDetails.firstName}{" "}
                          {zStore?.userDetails.lastName}
                        </div>
                      </Link>

                      <hr/>

                      <button onClick={logOutFunc}
                              className={"tw-text-gray-500 hover:tw-text-red-500 tw-transition-colors text-start"}
                              type={"button"}>
                        Sign Out
                      </button>
                    </div>
                  )}
                </div>

                <div className={"cursor-pointer tw-block lg:tw-hidden"} onClick={() => setOpen(prev => !prev)}>
                  <i className="bi bi-list fs-1"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
        className="position-absolute start-0 top-100 vw-100 py-2 border-top border-3 border-light-subtle
                border-end-0 border-start-0 border-bottom-0"
        style={{
          background: "linear-gradient(119.23deg,#e9dfff 4.63%,#edfaff 53.65%)",
          zIndex: 1000,
          transition: "all .25s ease-in-out",
          transform: open ? "unset" : "translateY(-125%)",
        }}>
        <div className={"container py-3"}>
          <div className='d-flex gap-3 align-items-center'>
            <Link to={"/profile"} className={"flex-shrink-0"}>
              <img className='tw-cursor-pointer rounded-4 tw-w-[46px] tw-h-[46px]'
                   src={zStore.userDetails?.profilePic || "/static/png/Male.png"}
                   alt=''/>
            </Link>
            <div className='small'>
              <p className='mb-0 montserrat small tw-font-[500] lh-1'>{greeting()},</p>
              <Link className='mb-0 montserrat fw-bold tw-text-[#410db7] small' to={"/profile"}>
                {zStore.userDetails?.firstName} {zStore.userDetails?.lastName}
              </Link>
            </div>
          </div>
        </div>

        <div className={"container d-flex flex-column gap-3 py-3"}>
          {MENU
            .filter(menu => menu.rules.whitelist ? menu.rules.whitelist.includes(role) : true)
            .filter(menu => menu.rules.blacklist ? !menu.rules.blacklist.includes(role) : true)
            .map((menu, i) => {
              return (
                <Link to={menu.href} className={`d-flex gap-3 align-items-center`} key={i}>
                  <img src={menu.img} alt='' width={"20px"} height={"20px"}/>
                  <span
                    className={`tw-text-xs montserrat tw-font-[500] tw-text-[#410db7] lh-base ${location.pathname.startsWith(menu.href) ? "fw-bold" : ""} ${menu.href === "#" ? "text-secondary" : ""}`}
                  >
                                            {menu.label}
                                        </span>

                  {(menu.href === "/notification" && notificationsCount > 0) && (
                    <div className="flex-grow-1 d-flex justify-content-end align-items-center">
                      <div
                        className="tw-text-xs tw-w-[18px] tw-h-[18px] bg-danger rounded-circle montserrat text-white ls-near d-flex justify-content-center align-items-center">
                        {notificationsCount}
                      </div>
                    </div>
                  )}

                  {(menu.href === "/chat" && messagedReveived) && (
                    <div className="flex-grow-1 d-flex justify-content-end align-items-center">
                      <div
                        className="tw-w-[10px] tw-h-[10px] bg-danger rounded-circle">
                      </div>
                    </div>
                  )}
                </Link>
              )
            })}

          <hr className={"m-0 p-0 border-2 tw-opacity-100 tw-border-[rgba(84,191,237,.5)!important]"}/>

          <div className='d-flex gap-3 align-items-center'>
            <img src={LogOut} alt='' width={"20px"} height={"20px"}/>
            <span
              className="tw-cursor-pointer tw-text-xs montserrat tw-font-[500] tw-text-[#410db7] lh-base"
              onClick={logOutFunc}>
                                Sign out
                            </span>
          </div>
        </div>
      </div>
    </div>

  )
}