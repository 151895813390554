import CreateEventModal from "../newsFeed/component/create_event_modal";
import {useState} from "react";

export default function CreateEventModalAdmin() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpenModal(true)}
        className="btn btn-primary rounded-circle tw-aspect-square"
      >
        <i className="bi bi-plus-lg" />
      </button>
      <CreateEventModal
        isOpenModal={isOpenModal}
        handleModal={() => {
          setIsOpenModal(false);
        }}
        setEventData={(data) => {
          window.location.reload()
        }}
      />
    </>
  )
}