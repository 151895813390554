import React, {useEffect, useState} from 'react';

import "./responsiveNav.css";
import {useNavigate} from 'react-router-dom';
import LogoTab from "../../../assets/wcn_logo.svg";
import NotificationBellResponsive from "../../../assets/svg/NotificationBellResponsive.svg";
import HamburgerResponsive from "../../../assets/svg/HamburgerResponsive.svg";
import NewsFeed from "../../../assets/feeds_icon.png";
import Notifications from "../../../assets/notification_icon.png";
import TimeExchange from "../../../assets/giving_icon.png";
import Community from "../../../assets/experiences_icon.png";
import Experts from "../../../assets/supporters_new.png";
import Chat from "../../../assets/chat_icon.png";
import UserListsvg from "../../../assets/user_list.png"
import QuestManagement from "../../../assets/svg/Quest Management.svg";
import LogOut from '../../../assets/signout_new_icon.png';
import {useDispatch, useSelector} from 'react-redux';
import {add, savePoints} from './../../../redux/actions/action';
import {getUserInformation} from '../../../utils/apiUrlsSetup';
import CloseBtn from '../../../assets/Crossbtn.png';
import ReportImg from '../../../assets/report.png'
import Friends from "../../../assets/friends_icon.png"
import {HOME} from "../../../config/ROUTES";

const ResponsiveNav = (props) => {
  const dispatch = useDispatch();
  const [orgId, setOrdId] = useState("");
  const [orgGroups, setOrgGroups] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const data = useSelector(state => state);

  const [showHamburger, setShowHamburger] = useState(true);

  const logOutFunc = () => {
    dispatch(add({}));
    dispatch(savePoints({}));
    localStorage.removeItem("accessToken");
    navigate('/login')
  }

  useEffect(() => {
    if (props.currOrgId) {
      setOrdId(props.currOrgId);
    }
  }, [props.currOrgId])

  useEffect(() => {
    if (!Object.keys(data.userDetails).length) {
      getUserDetails();
    } else {
      setUserDetails(data.userDetails.userDetails)
    }
  }, []);

  useEffect(() => {
    setShowHamburger(true);
  }, [props])

  const getUserDetails = async () => {
    let responseData = await getUserInformation();
    if (responseData.status === 200) {
      dispatch(add(responseData.data.data));
      setUserDetails(responseData.data.data)
    }
  }

  const role = userDetails.role


  return (
    <>
      <div className='row m-0 responsive_nav_bottom_border p-0'>
        <div className='col-12 p-0 ps-4 pe-4'>

          <div className='row m-0 pt-md-4 pb-md-4'>
            <div className='col-3 p-0 d-flex align-items-center'>
              <img className='responsive_nav_logo' src={LogoTab} alt='' onClick={() => {
                navigate(HOME)
              }}/>
            </div>

            <div className='col-5 p-0 d-flex align-items-center justify-content-center'>
              <p className='mb-0 responsive_page_name'>{props.heading}</p>
            </div>

            <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
              <img className='me-4 responsive_nav_notification_bell' src={NotificationBellResponsive} alt=''/>
              <img onClick={() => setShowHamburger(!showHamburger)}
                   className='responsive_nav_hamburger cursor_pointer pe-0'
                   class="navbar-toggler navbar-toggler-class pe-0" data-bs-toggle="collapse"
                   data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
                   aria-label="Toggle navigation"
                   src={showHamburger ? HamburgerResponsive : CloseBtn} alt='' width="45px" height="30px"/>
            </div>
          </div>

        </div>
      </div>

      <>
        <nav class="navbar navbar-expand-lg p-0">

          <div class="container-fluid tablet_padding m-0 p-0">

            <div class={`collapse navbar-collapse px-4 ${!showHamburger ? 'show' : ''}`}
                 style={{background: 'linear-gradient(119.23deg, #e9dfff 4.63%, #edfaff 53.65%)'}} id="navbarText">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 py-3">

                <div className='row m-0 mt-2 mb-3'>
                  <div className='col-md-1 col-sm-1 col-2 p-0 me-2'>
                    <div className='profile_div'><img className='profile_img cursor_pointer'
                                                      src={props.profilePic || userDetails.profilePic} alt=''
                                                      onClick={() => {
                                                        navigate('/profile')
                                                      }}/></div>
                  </div>
                  <div className='col-9 p-0 d-flex flex-column justify-content-center'>
                    <p className='mb-0 profile_wish ms-1'>Good afternoon,</p>
                    <p className='mb-0 profile_name ms-1' onClick={() => {
                      navigate('/profile')
                    }}>{(props.userData && props.userData.firstName) || userDetails.firstName} {(props.userData && props.userData.lastName) || userDetails.lastName}</p>
                  </div>
                </div>

                <li class="nav-item ms-lg-5">
                  <div className='row m-0'>
                    <div className='col-12 p-0 d-flex'>
                      <img className='desh_img responsive_img_dimension' src={NewsFeed} alt='logo'/><p
                      className={`mb-0 ms-3 sidenav_link ${props.headerName === "News Feed" ? 'link_active' : ''}`}
                      onClick={() => {
                        navigate('/dashboard')
                      }}>Feeds</p>
                    </div>
                  </div>
                </li>

                {
                  role === 'super-admin' ? (
                    <div className='row m-0 mt-3'>
                      <div className='col-12 p-0 d-flex'>
                        <img className='desh_img responsive_img_dimension' src={UserListsvg} alt='Rocket'/><p
                        className={`mb-0 ms-3 sidenav_link  ${props.headerName === 'User List' ? 'link_active' : ''}`}
                        onClick={() => {
                          navigate('/userlist')
                        }}>User List</p>
                      </div>
                    </div>
                  ) : null
                }


                {
                  role !== 'super-admin' ? (
                    <div className='row m-0 mt-3'>
                      <div className='col-12 p-0 d-flex'>
                        <img className='desh_img responsive_img_dimension' src={Community} alt='logo'/><p
                        className={`mb-0 ms-3 sidenav_link ${props.headerName === 'Experiences' ? 'link_active' : ''}`}
                        onClick={() => {
                          navigate('/communities')
                        }}>Communities</p>
                      </div>
                    </div>
                  ) : null
                }

                {/* {
                                    role !== 'super-admin' ? (
                                        <div className='row m-0 mt-3'>
                                            <div className='col-12 p-0 d-flex'>
                                                <img className='desh_img responsive_img_dimension' config={ProgramImg} alt='logo' /><p className={`mb-0 ms-3 sidenav_link ${props.headerName === "Programmes" ? 'link_active' : ''}`} onClick={() => { navigate('/programmes') }}>Programmes</p>
                                            </div>
                                        </div>
                                    ) : null
                                } */}

                {
                  role === 'super-admin' ? (
                    <div className='row m-0 mt-3'>
                      <div className='col-12 p-0 d-flex'>
                        <img style={{width: '20px', height: '20px'}} className='desh_img' src={ReportImg} alt='Rocket'/>
                        <p className={`mb-0 ms-3 sidenav_link  ${props.headerName === 'Reports' ? 'link_active' : ''}`}
                           onClick={() => {
                             navigate('/reports')
                           }}>Reports</p>
                      </div>
                    </div>
                  ) : null
                }


                {
                  // role !== 'super-admin' ? (
                  //     <div className='row m-0 mt-3'>
                  //         <div className='col-12 p-0 d-flex'>
                  //             <img className='desh_img' config={QuestManagement} alt='Rocket' /><p className={`mb-0 ms-3 sidenav_link link_deactive ${props.QuestManagement ? 'link_active' : ''}`}>Quests</p>
                  //         </div>
                  //     </div>
                  // ) : null
                }


                {
                  role !== 'super-admin' ? (
                    <div className='row m-0 mt-3'>
                      <div className='col-12 p-0 d-flex'>
                        <img className='desh_img responsive_img_dimension' src={TimeExchange} alt='TimeExchange'/><p
                        className={`mb-0 ms-3 sidenav_link ${props.headerName === "Giving" ? 'link_active' : ''}`}
                        onClick={() => {
                          navigate('/time-exchange')
                        }}>Giving</p>
                      </div>
                    </div>
                  ) : null
                }

                {
                  role === 'super-admin' ? (
                    <div className='row m-0 mt-3'>
                      <div className='col-12 p-0 d-flex'>
                        <img className='desh_img responsive_img_dimension' src={Community} alt='logo'/><p
                        className={`mb-0 ms-3 sidenav_link ${props.headerName === 'Experiences' ? 'link_active' : ''}`}
                        onClick={() => {
                          navigate('/admin/experiences')
                        }}>Experiences</p>
                      </div>
                    </div>
                  ) : null
                }
                {
                  role === 'super-admin' ? (
                    <div className='row m-0 mt-3'>
                      <div className='col-12 p-0 d-flex'>
                        <img className='desh_img' src={QuestManagement} alt='Rocket'/><p
                        className={`mb-0 ms-3 sidenav_link link_deactive ${props.QuestManagement ? 'link_active' : ''}`}>Quest
                        Management</p>
                      </div>
                    </div>
                  ) : null
                }

                {
                  role !== 'super-admin' ? (
                    <div className='row m-0 mt-3'>
                      <div className='col-12 p-0 d-flex'>
                        <img className='desh_img responsive_img_dimension' src={Experts} alt='logo'/><p
                        className={`mb-0 ms-3 sidenav_link ${props.headerName === "Expert" ? 'link_active' : ''}`}
                        onClick={() => {
                          navigate('/supporters')
                        }}>Supporters</p>
                      </div>
                    </div>
                  ) : null
                }


                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 d-flex'>
                    <img className='desh_img responsive_img_dimension' src={Friends} alt=''/><p
                    className={`mb-0 ms-3 sidenav_link ${props.headerName === "Settings" ? 'link_active' : ''}`}
                    onClick={() => {
                      navigate('/friends')
                    }}>Friends</p>
                  </div>
                </div>

                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 d-flex'>
                    <img className='desh_img responsive_img_dimension' src={Chat} alt='Chat'/><p
                    className={`mb-0 ms-3 sidenav_link ${props.headerName === "Chat" ? 'link_active' : ''}`}
                    onClick={() => {
                      navigate('/chat')
                    }}>Chat</p>
                  </div>
                </div>

                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 d-flex'>
                    <img className='desh_img responsive_img_dimension' src={Notifications} alt='Notification'/><p
                    className={`mb-0 ms-3 sidenav_link ${props.headerName === "Notification" ? 'link_active' : ''}`}
                    onClick={() => {
                      navigate('/notification')
                    }}>Notifications</p>
                  </div>
                </div>

                {
                  // role !== 'super-admin' ? (
                  //     <div className='row m-0 mt-3'>
                  //         <div className='col-12 p-0 d-flex'>
                  //             <img className='desh_img' config={QuestManagement} alt='Rocket' /><p className={`mb-0 ms-3 sidenav_link link_deactive ${props.QuestManagement ? 'link_active' : ''}`}>Wellbeing Analysis</p>
                  //         </div>
                  //     </div>
                  // ) : null
                }


                <div className='row m-0 mt-3 top_brd_side_foot_parrent'>
                  <div className='col-12 p-0 top_brd_side_foot d-flex'>

                  </div>
                </div>


                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 d-flex'>
                    <img className='desh_img responsive_img_dimension' width='17px' height='17px' src={LogOut} alt=''/>
                    <p className="mb-0 ms-3 sidenav_link" onClick={() => logOutFunc()}>Sign out</p>
                  </div>
                </div>
              </ul>
            </div>
          </div>

        </nav>

      </>
    </>
  );
}

export default ResponsiveNav;