import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import ForgetPassword from '../container/staticPages/forgetPassword/forgetPassword';
import ResetPassword from '../container/staticPages/resetPassword/resetPassword';
import SignUp from '../container/staticPages/signUp/SignUp';

import Home from './Home';
import About from './about/About';
import FoundingTeam from "./founding-team/FoundingTeam"
import ContactUs from './contactUs/ContactUs';

import Dashboard from './Dashboard/Dashboard.tsx';

import NewsFeeds from './NewsFeeds/NewsFeeds';
import NewsFeed from '../container/newsFeed/newsFeed/newsFeed';

import Feelings from './feelings/Feeling';
import Functioning from './functioning/Functioning';
import Connecting from './connecting/Connecting';
import Community from './community/Community';
import Notification from '../container/staticPages/notification/notification';

import Journey from '../container/journey/journey';

import CommunityTab from '../container/community/communityTab/communityTab';
import CommunityTabCoaches from '../container/community/community-coaches/communityCoaches';
import Experts from '../container/experts/experts';
import ExpertsSession from '../container/experts/expert1to1';
import ExpertsSearch from '../container/experts/expertsSearch';
import ExpertsFrame from '../container/experts/expertsFrame';

import Setting from '../container/staticPages/setting/setting';
import YourQuest from '../container/journey/yourQuest';
import QuestDetails from '../container/journey/questDetails';
import Leaderboards from '../container/journey/leaderboards';
import ExploreQuests from '../container/journey/exploreQuests';
import QuestInvitations from '../container/journey/questInvitations';
import ExploreQuestInvitations from '../container/journey/exploreQuestInvitations';
import Exchanges from '../container/timeExchange/Exchanges.tsx';
import Exchange from '../container/timeExchange/Exchange.tsx';
import Onboard from '../container/staticPages/Onboard/onboard';
import Chat from '../container/staticPages/chat';

import SuperAdmin from '../container/superAdmin/superAdmin';
import CoachProfile from '../container/superAdmin/coachProfile';
import UserProfile from '../container/superAdmin/userProfile';
import HuddleList from '../container/superAdmin/huddleList';
import Friends from '../container/friends/friends';
import EditProfile from '../container/staticPages/editProfile/editProfile';
import Profile from '../container/staticPages/viewProfile/Profile';

import CreateOfferForm from '../container/timeExchange/component/CreateOfferForm';
import CreateRequestForm from '../container/timeExchange/component/CreateRequestForm.tsx';
import YourExchange from '../container/timeExchange/yourExchange';
import CommunityHuddles from '../container/community/communityHuddle/communityHuddles';
import UserList from '../container/superAdmin/UserList';
import Organisation from '../container/superAdmin/Organisation';

import Organization from '../container/organization/organization/Organisation.tsx';
import OrganizationMob from '../container/organization/organisation-mob';
import OrganizationAdminMob from '../container/organization/organisation-admin-mob';
import Members from '../container/organization/members';
import OrganisationProfile from '../container/superAdmin/OrganisationProfile';

import ManageHuddle from '../container/community/manage-huddle/manage-huddle';

import Experiences from '../container/superAdmin/Experiences'
import NewOrganisation from '../container/organization/organization/newOrganisation';
import SeeAllOrganisation from '../container/organization/organization/seeAllOrganisation';
import Communities from '../container/program/Communities.tsx';
import NotFound from './NotFound';
import PubNub from "pubnub";
import {PubNubProvider} from "pubnub-react";
import Reports from '../container/superAdmin/Report'
import Program from '../container/program/Program';
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "./global.css"
import {Buffer} from 'buffer';
import VerifyEmail from "./VerifyEmail";
import {HOME} from "../config/ROUTES";
import NewsFeedsByTags from "./NewsFeeds/tags/[tag]/NewsFeedsByTags";
import P from "./NewsFeeds/P";
import {useZStore} from "../utils/zStore.ts";
import Events from "../container/program/Events.tsx";

// @ts-ignore
window.Buffer = Buffer;

function Routing() {
  const token = localStorage.getItem('accessToken')
  const store = useZStore()
  const location = useLocation()

  const getPubub = () => {
    return new PubNub({
      publishKey: "pub-c-b67b4907-0dfd-47b8-a72e-a31b7e6e3bb7",
      subscribeKey: "sub-c-8a4ff8fc-9b97-11ec-9191-420d26494bdd",
      uuid: `user${store.userDetails?.id}`
    });
  }

  useEffect(() => {
    getPubub(store.userDetails);
  }, [store.userDetails, getPubub]);

  useEffect(() => {
    if (location.pathname.includes('/event/')) {
      localStorage.setItem('link', location.pathname)
    }
  }, [location])


  return (
    <>
      <PubNubProvider client={getPubub()}>
        <Routes>

          {/* 404 Handler */}
          <Route
            path='*'
            element={<NotFound/>}
          />

          {/* Landing Page */}
          <Route
            path='/'
            element={!token ? <Home/> : <Navigate to={HOME}/>}
            exact
          />

          {/* About Page */}
          <Route
            path='/about'
            element={!token ? <About/> : <Navigate to={HOME}/>}
            exact
          />
          <Route
            path='/founding-team'
            element={!token ? <FoundingTeam/> : <Navigate to={HOME}/>}
            exact
          />

          {/* Feelings, Functioning, Connecting */}
          <Route
            path='/feelings'
            element={!token ? <Feelings/> : <Navigate to={HOME}/>}
            exact
          />
          <Route
            path='/functioning'
            element={!token ? <Functioning/> : <Navigate to={HOME}/>}
            exact
          />
          <Route
            path='/connecting'
            element={!token ? <Connecting/> : <Navigate to={HOME}/>}
            exact
          />

          <Route
            path='/community'
            element={!token ? <Community/> : <Navigate to={HOME}/>}
            exact
          />

          <Route
            path='/contactus'
            element={!token ? <ContactUs/> : <Navigate to={HOME}/>}
            exact
          />

          <Route
            path='/signup'
            element={!token ? <SignUp/> : <Navigate to={HOME}/>}
            exact
          />
          <Route
            path='/forget-password'
            element={<ForgetPassword/>}
            exact
          />

          <Route
            path='/reset-password'
            element={!token ? <ResetPassword/> : <Navigate to={HOME}/>}
            exact/>

          <Route
            path='/reset-password/:token'
            element={!token ? <ResetPassword/> : <Navigate to={HOME}/>}
            exact/>

          <Route
            path='/signup'
            element={!token ? <SignUp/> : <Navigate to={HOME}/>}
            exact
          />

          <Route
            path='/admin/manage-account'
            element={token ? <SuperAdmin/> : <Navigate to="/login"/>}
            exact/>
          <Route
            path='/admin/coach-profile/:id'
            element={token ? <CoachProfile/> : <Navigate to="/login"/>}
            exact/>
          <Route
            path='/admin/user-profile/:id'
            element={token ? <UserProfile/> : <Navigate to="/login"/>}
            exact/>
          <Route
            path='/admin/huddle-list'
            element={token ? <HuddleList/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/userlist'
            element={token ? <UserList/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/reports'
            element={token ? <Reports/> : <Navigate to="/"/>}
            exact
          />
          <Route
            path='/organisation'
            element={token ? <Organisation/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/friends/:id'
            element={token ? <Friends/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/notification'
            element={token ? <Notification/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/time-exchange'
            element={token ? <Exchanges/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/time-exchange/exchange/create-offer'
            element={token ? <CreateOfferForm/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/time-exchange/exchange/create-request'
            element={token ? <CreateRequestForm/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/time-exchange/exchange'
            element={token ? <Exchange/> : <Navigate to="/login"/>}
            exact/>

          <Route
            path='/time-exchange/yourExchange'
            element={token ? <YourExchange/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/journey'
            element={token ? <Journey/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/journey/quests'
            element={token ? <YourQuest/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/journey/quest'
            element={token ? <QuestDetails/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/journey/leaderboard'
            element={token ? <Leaderboards/> : <Navigate to="/login"/>}
            exact/>
          <Route
            path='/journey/quests/explorequest'
            element={token ? <ExploreQuests/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/journey/quests/questinvitation'
            element={token ? <QuestInvitations/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/journey/quests/explorequestinvitation'
            element={token ? <ExploreQuestInvitations/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/supporters'
            element={token ? <Experts/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/supporters/sessions'
            element={token ? <ExpertsSession/> : <Navigate to="/login"/>}
            exact/>
          <Route
            path='/supporters/supporter-search'
            element={token ? <ExpertsSearch/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/supporters/expert-frame'
            element={token ? <ExpertsFrame/> : <Navigate to="/login"/>}
            exact/>

          <Route
            path='/verify-email'
            element={<VerifyEmail/>}
            exact/>

          <Route
            path='/verify-email/:token'
            element={<VerifyEmail/>}
            exact/>

          <Route
            path='/dashboard'
            element={token ? <Dashboard/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/p/:id'
            element={token ? <P/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/newsfeeds/tags/:tag'
            element={token ? <NewsFeedsByTags/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/event/:id'
            element={token ? <NewsFeed/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/friends'
            element={token ? <Setting/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/editprofile'
            element={token ? <EditProfile/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/profile'
            element={token ? <Profile/> : <Navigate to="/login"/>}
            exact
          />


          <Route
            path='/community-tab'
            element={token ? store.userDetails?.role === "user" ? <CommunityTab/> :
              <CommunityTab/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/mycalendar'
            element={token ? <CommunityTabCoaches/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/communitytab/feeling'
            element={token ? <CommunityHuddles communityType="communityFeelings"/> :
              <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/communitytab/functioning'
            element={token ? <CommunityHuddles communityType="communityFunctioning"/> :
              <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/manage-huddle'
            element={<ManageHuddle/>}
            exact
          />

          <Route
            path='/admin/experiences'
            element={token ? <Experiences/> : <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/chat'
            element={token ? <Chat/> : <Navigate to="/login"/>}
            exact
          />
          <Route
            path='/login'
            element={<Navigate to="/"/>}
            exact
          />
          <Route
            path='/onboarding'
            element={<Onboard/>}
            exact
          />
          <Route
            path='/communitytab/connecting'
            element={token ? <CommunityHuddles communityType="communityConnecting"/> :
              <Navigate to="/login"/>}
            exact
          />

          <Route
            path='/new-organization'
            element={<NewOrganisation/>}
            exact
          />
          <Route
            path='/all-organization'
            element={<SeeAllOrganisation/>}
            exact
          />
          <Route
            path='/communities'
            element={<Communities/>}
            exact
          />
          <Route
            path='/events'
            element={<Events/>}
            exact
          />
          <Route
            path='/programme/:id'
            element={<Program/>}
            exact
          />
          <Route
            path='/organisation/:id'
            element={<Organization/>}
            exact
          />
          <Route
            path='/new-organization-member'
            element={<Members/>}
            exact
          />
          <Route
            path='/organisation-profile/:id'
            element={<OrganisationProfile/>}
            exact
          />
          <Route
            path='/organisation-profile'
            element={<OrganisationProfile/>}
            exact
          />
          <Route
            path='/organization-mobile'
            element={<OrganizationMob/>}
            exact
          />
          <Route
            path='/organization-admin-mobile'
            element={<OrganizationAdminMob/>}
            exact
          />

        </Routes>
      </PubNubProvider>


    </>
  )
}

export default Routing;
