import {ReactNode, useEffect} from "react";
// @ts-ignore
import TopNav from "../staticPages/TopNav.tsx";
// @ts-ignore
import {useZStore} from "../../utils/zStore.ts";
import {useDispatch} from "react-redux";
import {add, savePoints, saveTourData} from "../../redux/actions/action";

export default function LoggedInLayout(props: { children: ReactNode; title: string; className?: string; }) {
  const {children, title, className = ""} = props;
  const zStore = useZStore()
  const dispatch = useDispatch();

  useEffect(() => {
    if (!zStore.userDetails) {
      dispatch(add({}));
      dispatch(savePoints({}));
      dispatch(saveTourData({}));
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("notificationsCount");
      window.location.href = "/"
    }
  }, [dispatch, zStore.userDetails]);

  if (!zStore.userDetails) {
    return <></>
  }

  return (
    <main className={`bg-logged-in ${className}`}>
      <TopNav title={title}/>

      <div className="tw-container pb-lg-5 tw-pt-16 lg:tw-pt-24 tw-pb-24 tw-min-h-screen">
        {children}
      </div>
    </main>
  )
}