import React from 'react';
import '../../container/newsFeed/newsFeeds/newsFeeds.css';
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import FeedsSubPage from "../../component/FeedsSubPage.tsx";
import {useParams} from "react-router";

const P = () => {
  const {id} = useParams();
  return (
    <>
      <LoggedInLayout title={"Feeds"}>
        <FeedsSubPage postId={id}/>
      </LoggedInLayout>
    </>
  )
}

export default P;


